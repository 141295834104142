import React from "react";
import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  card: {
    backgroundImage: "url(/images/Confetti.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
    padding: theme.spacing(20, 6),
  },
  title: {
    fontSize: "52px",
    lineHeight: 1,
  }
}));

export default function Dashboard() {
  const classes = useStyles();

  return (
    <div>
      {/* <h1>Dashboard</h1> */}
      <Card className={classes.card}>
        <CardContent>
          <Typography align="center" className={classes.title}>
            COMING
          </Typography>
          <Typography align="center" className={classes.title} gutterBottom>
            SOON
          </Typography>
          <Typography variant="body1" align="center">Portal under construction</Typography>

        </CardContent>
      </Card>
    </div>
  );
}
