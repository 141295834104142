import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ListItem, ListItemText, Collapse, List, ListItemIcon, makeStyles } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Icon from '@material-ui/core/Icon';
import { connect } from 'react-redux';
import { drawerToggle } from '../../components/Portal/redux/componentsActions';


const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(6),
    }
}));

function MenuItem(props) {
    const classes = useStyles();

    const [toggle, setToggle] = useState(false);

    const handleToggle = () => {        
        if (props.drawerOpen) {
            setToggle(!toggle);
        } else {
            props.openAction();
        }
    };

    if (props.depth !== "1") {
        return (
            <div>
                <ListItem button={true} onClick={handleToggle}>
                    <ListItemIcon>
                        <Icon>{props.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText primary={props.label} />
                    {toggle ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={props.drawerOpen && toggle} timeout="auto" unmountOnExit>
                    <List>
                        {props.subMenu.map((i) => {
                            return (
                                <ListItem key={i.label} button={true} component={Link} to={i.path}  className={classes.nested}>
                                    <ListItemText primary={i.label} />
                                </ListItem>
                            )
                        })}
                    </List>
                </Collapse>
            </div>
        )
    };

    return (
        <ListItem button={true} component={Link} to={props.path}>
            <ListItemIcon>
                <Icon>{props.icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={props.label} />
        </ListItem>
    );
};

const mapStateToProps = (state) => {
    return {
        drawerOpen: state.drawerOpen,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        openAction: () => {
            dispatch(drawerToggle())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItem);
// export default MenuItem;
