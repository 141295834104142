import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    GridList,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@material-ui/core";
import "./index.css";
import api_endpoints from "../../constants/api_endpoints";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(2, "auto"),
        padding: theme.spacing(3),
        width: "90%",
    },
    img: {
        width: "30%",
    },
    font1: {
        color: theme.palette.text.primary,
        fontSize: "26px",
        letterSpacing: "-0.24px",
        lineHeight: "40px",
    },
    divider: {
        borderBottom: "3px dashed #bdbdbd",
    },
    infoGrid: {
        margin: theme.spacing(2, 0),
    },
    repNotes: {
        border: "1px solid black",
        borderRadius: "5px",
        margin: theme.spacing(0, 0, 2),
        padding: theme.spacing(2),
    },
    table: {
        padding: theme.spacing(5),
        marginTop: theme.spacing(1),
    },
    borders: {
        border: "2px solid black",
    },
    innerBorders: {
        borderLeft: "2px solid black",
        borderRight: "2px solid black",
    },
    topBorder: {
        borderTop: "2px solid black",
    },
    bottomBorders: {
        borderLeft: "2px solid black",
        borderRight: "2px solid black",
        borderBottom: "2px solid black",
    },
    covers: {
        overflow: "hidden",
        width: "100%",
    },
    coverContainer: {
        display: "flex",
        flexWrap: "wrap",
        padding: theme.spacing(1, 2),
    },
    cover: {
        maxWidth: "170px",
        padding: theme.spacing(2, 0),
        margin: theme.spacing(1, 0),
    },
    notInvoice: {
        backgroundColor: "#e0e0e0",
    },
    btn: {
        textAlign: "center",
        margin: theme.spacing(4),
    },
    signature: {
        padding: theme.spacing(2, 0),
    },
    notes: {
        padding: theme.spacing(1, 2),
    },
    highlight: {
        backgroundColor: "#FFFF5C",
    },
    textField: {
        padding: theme.spacing(1, 2),
    },
    signatureLabel: {
        margin: theme.spacing(2, 2, 0),
    },
    signatureBox: {
        border: "1px solid #bdbdbd",
        margin: theme.spacing(0, 2),
        padding: theme.spacing(4),
        textAlign: "center",
    },
}));

const Contract = (props) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const [signature, setSignature] = useState("");
    const [date, setDate] = useState("");
    const [notes, setNotes] = useState("");
    const [error, setError] = useState("");
    const [validSubmittion, setValidSubmittion] = useState(false);

    const [clientsData, setClientsData] = useState({});

    const [clientKey, setClientKey] = useState("");

    const [invalidLink, setInvalidLink] = useState(false);
    const handleClose = () => {
        setOpen(!open);
    };

    const handleNotes = (e) => {
        setNotes(e.target.value);
    };

    const handleSignature = (e) => {
        setSignature(e.target.value);
    };

    useEffect(() => {
        const today = new Date();
        const date = today.getDate();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        setDate(`${year} - ${month} - ${date}`);

        const url = window.location.href;
        // const keyIndex = props.history.location.pathname.lastIndexOf("/");
        // const key = props.history.location.pathname.substring(keyIndex + 1);

        if (url.split("=")[1]) {
            // console.log(url.split("="));
            const startKey = url.split("=")[1];
            const key = startKey.split("&")[0];
            const idIndex = url.lastIndexOf("=");
            const contractId = url.substring(idIndex + 1);
            // const contractId = url.split("=")[1];
            // console.log(key, idIndex, contractId);

            // console.log(key, contractId);
            setClientKey(key);

            //http://localhost:3000/contract?key={{ContractKey}}&contractId={{ContractId}}
            //http://localhost:3000/contract?key=f24bd331-9b75-4ab8-bab0-92148701c459&contractId=9476
            //https://my.firstclassplanners.ca/contract/?key=5362e101-b34c-4de3-9bec-6b66d0666d9d&contractId=8549
            // console.log(api_endpoints.api().client_contract + key + "?contract=" + contractId);
            fetch(api_endpoints.api().client_contract + key + "?contract=" + contractId)
                .then((res) => {
                    if (res.ok) {
                        setOpen(true);
                        return res.json();
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    // console.log(data);
                    setClientsData(data);
                })
                .catch((err) => {
                    setInvalidLink(true);
                    console.log("Error:", err);
                });
        } else {
            setInvalidLink(true);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dateFormat = (dateString, dateString2) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        if (dateString !== null) {
            const d = new Date(dateString);

            if (dateString2 !== undefined) {
                const d2 = new Date(dateString2);
                const sameMonth = d.getMonth() === d2.getMonth();
                return sameMonth
                    ? months[d2.getMonth()] + " " + d.getDate() + " - " + d2.getDate() + ", " + d.getFullYear()
                    : months[d.getMonth()] +
                          " " +
                          d.getDate() +
                          " - " +
                          months[d2.getMonth()] +
                          " " +
                          d2.getDate() +
                          ", " +
                          d.getFullYear();
            }
            return months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
        }
        return "N/A";
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let tmp = {
            result: notes,
            signature: signature,
        };

        fetch(api_endpoints.api().signing + clientKey, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(tmp),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                console.log(data);
                if (data.Signature) {
                    setError(data.Signature);
                } else {
                    // console.log("/signup?" + data.message);
                    // props.history.push("/signup?" + data.message);
                    setValidSubmittion(true);
                }
            })
            .catch((err) => {
                setError("Internal Server Error: please refresh and try again, or contact developer.");
                console.error("Error: ", err);
            });
    };

    return (
        <>
            {Object.entries(clientsData).length !== 0 && (
                <>
                    <Paper className={clsx("section-to-print", classes.paper)}>
                        <Grid container alignItems="center">
                            <Grid item md={6}>
                                <img
                                    src="/images/FirstClassLogoColour.jpg"
                                    alt="Company Logo"
                                    className={classes.img}
                                />
                            </Grid>
                            <Grid item md={3} align="left">
                                <Typography className={classes.font1}>{clientsData.schoolYear}</Typography>
                            </Grid>
                            <Grid item md={3} align="right">
                                <Typography className={classes.font1}>Order Form</Typography>
                                <Typography variant="subtitle2" display="inline">
                                    Client ID: {clientsData.schoolNumber}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Divider className={classes.divider} />

                        <Grid container spacing={1} className={classes.infoGrid}>
                            <Grid container item>
                                <Grid item md={6} sm={12}>
                                    <Typography variant="subtitle2" display="inline">
                                        School Name:{" "}
                                    </Typography>
                                    <Typography variant="subtitle1" display="inline">
                                        {clientsData.schoolName}
                                    </Typography>
                                </Grid>
                                <Grid item md={6} sm={12}>
                                    <Typography variant="subtitle2" display="inline">
                                        Contract Number:{" "}
                                    </Typography>
                                    <Typography variant="subtitle1" display="inline">
                                        {clientsData.contractId}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item>
                                <Grid item md={6} sm={12}>
                                    <Typography variant="subtitle2" display="inline">
                                        School Board:{" "}
                                    </Typography>
                                    <Typography variant="subtitle1" display="inline">
                                        {clientsData.schoolBoard}
                                    </Typography>
                                </Grid>
                                <Grid item md={6} sm={12}>
                                    <Typography variant="subtitle2" display="inline">
                                        PO Number:{" "}
                                    </Typography>
                                    <Typography variant="subtitle1" display="inline">
                                        {clientsData.poNumber}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item>
                                <Grid item md={6} sm={12}>
                                    <Typography variant="subtitle2" display="inline">
                                        Ship To:{" "}
                                    </Typography>
                                    <Typography variant="subtitle1" display="inline">
                                        {clientsData.shipTo}
                                    </Typography>
                                </Grid>
                                <Grid item md={6} sm={12}>
                                    <Typography variant="subtitle2" display="inline">
                                        Sales Rep:{" "}
                                    </Typography>
                                    <Typography variant="subtitle1" display="inline">
                                        {clientsData.salesRepName}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item>
                                <Grid item md={6} sm={12}>
                                    <Typography variant="subtitle2" display="inline">
                                        Invoice To:{" "}
                                    </Typography>
                                    <Typography variant="subtitle1" display="inline">
                                        {clientsData.invoiceTo}
                                    </Typography>
                                </Grid>
                                <Grid item md={6} sm={12}>
                                    <Typography variant="subtitle2" display="inline">
                                        Agenda Series:{" "}
                                    </Typography>
                                    <Typography variant="subtitle1" display="inline">
                                        {clientsData.contractSeries}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item>
                                <Grid item md={6} sm={12}>
                                    <Typography variant="subtitle2" display="inline">
                                        Primary Name:{" "}
                                    </Typography>
                                    <Typography variant="subtitle1" display="inline">
                                        {clientsData.primaryContactName}
                                    </Typography>
                                </Grid>
                                <Grid item md={6} sm={12}>
                                    <Typography variant="subtitle2" display="inline">
                                        Primary Email:{" "}
                                    </Typography>
                                    <Typography variant="subtitle1" display="inline">
                                        {clientsData.primaryContactEmail}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item>
                                <Grid item md={6} sm={12}>
                                    <Typography variant="subtitle2" display="inline">
                                        Secondary Name:{" "}
                                    </Typography>
                                    <Typography variant="subtitle1" display="inline">
                                        {clientsData.secondaryContactName}
                                    </Typography>
                                </Grid>
                                <Grid item md={6} sm={12}>
                                    <Typography variant="subtitle2" display="inline">
                                        Secondary Email:{" "}
                                    </Typography>
                                    <Typography variant="subtitle1" display="inline">
                                        {clientsData.secondaryContactEmail}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box className={classes.repNotes}>
                            <strong>NOTES: </strong>
                            <Typography
                                display="inline"
                                className={clientsData.contractNotes.length !== 0 && classes.highlight}
                            >
                                {clientsData.contractNotes}
                            </Typography>
                        </Box>
                        <Table className={clsx(classes.borders, classes.table)} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.bottomBorders} align="center">
                                        Quantity
                                    </TableCell>
                                    <TableCell className={classes.bottomBorders} colSpan={2}>
                                        Description
                                    </TableCell>
                                    <TableCell className={classes.bottomBorders} align="center">
                                        Unit Price
                                    </TableCell>
                                    <TableCell className={classes.bottomBorders} align="center">
                                        Amount
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clientsData.planners.length !== 0 &&
                                    clientsData.planners.map((p, index) => (
                                        <React.Fragment key={index}>
                                            <TableRow>
                                                <TableCell className={classes.innerBorders} align="center">
                                                    <strong>{p.quantity}</strong>
                                                </TableCell>
                                                <TableCell className={classes.innerBorders} colSpan={2}>
                                                    <strong>{p.description}</strong>
                                                </TableCell>
                                                <TableCell className={classes.innerBorders} align="center">
                                                    ${p.unitPrice.toFixed(2)}
                                                </TableCell>
                                                <TableCell className={classes.innerBorders} align="right">
                                                    ${p.total.toFixed(2)}
                                                </TableCell>
                                            </TableRow>
                                            {!p.frontCover.description.includes("Custom") && (
                                                <TableRow>
                                                    <TableCell className={classes.innerBorders} align="center">
                                                        {p.frontCover.quantity}
                                                    </TableCell>
                                                    <TableCell className={classes.innerBorders} colSpan={2}>
                                                        {p.frontCover.description}
                                                    </TableCell>
                                                    <TableCell className={classes.innerBorders} align="center">
                                                        ${p.frontCover.unitPrice.toFixed(2)}
                                                    </TableCell>
                                                    <TableCell className={classes.innerBorders} align="right">
                                                        ${p.frontCover.total.toFixed(2)}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            {!p.backCover.description.includes("Custom") && (
                                                <TableRow>
                                                    <TableCell className={classes.innerBorders} align="center">
                                                        {p.backCover.quantity}
                                                    </TableCell>
                                                    <TableCell className={classes.innerBorders} colSpan={2}>
                                                        {p.backCover.description}
                                                    </TableCell>
                                                    <TableCell className={classes.innerBorders} align="center">
                                                        ${p.backCover.unitPrice.toFixed(2)}
                                                    </TableCell>
                                                    <TableCell className={classes.innerBorders} align="right">
                                                        ${p.backCover.total.toFixed(2)}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            {p.supplements.map((s, i) => (
                                                <TableRow key={i}>
                                                    <TableCell className={classes.innerBorders} align="center">
                                                        {s.quantity}
                                                    </TableCell>
                                                    <TableCell className={classes.innerBorders} colSpan={2}>
                                                        {s.description}
                                                    </TableCell>
                                                    <TableCell className={classes.innerBorders} align="center">
                                                        ${s.unitPrice.toFixed(2)}
                                                    </TableCell>
                                                    <TableCell className={classes.innerBorders} align="right">
                                                        ${s.total.toFixed(2)}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            {p.schoolPagesBW !== null && p.schoolPagesBW.itemQuantity > 0 && (
                                                <TableRow>
                                                    <TableCell className={classes.innerBorders} align="center">
                                                        {p.quantity}
                                                    </TableCell>
                                                    <TableCell className={classes.innerBorders} colSpan={2}>
                                                        {p.schoolPagesBW.description}
                                                    </TableCell>
                                                    <TableCell className={classes.innerBorders} align="center">
                                                        ${p.schoolPagesBW.unitPrice.toFixed(2)}
                                                    </TableCell>
                                                    <TableCell className={classes.innerBorders} align="right">
                                                        ${p.schoolPagesBW.total.toFixed(2)}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            {p.schoolPagesColour !== null && p.schoolPagesColour.itemQuantity > 0 && (
                                                <TableRow>
                                                    <TableCell className={classes.innerBorders} align="center">
                                                        {p.quantity}
                                                    </TableCell>

                                                    <TableCell className={classes.innerBorders} colSpan={2}>
                                                        {p.schoolPagesColour.description}
                                                    </TableCell>
                                                    <TableCell className={classes.innerBorders} align="center">
                                                        {/* $
                                                        {(
                                                            p.schoolPagesColour.unitPrice *
                                                            p.schoolPagesColour.itemQuantity
                                                        ).toFixed(2)} */}
                                                        ${p.schoolPagesColour.unitPrice.toFixed(2)}
                                                    </TableCell>
                                                    <TableCell className={classes.innerBorders} align="right">
                                                        {/* $
                                                        {(
                                                            p.schoolPagesColour.total * p.schoolPagesColour.quantity
                                                        ).toFixed(2)} */}
                                                        ${p.schoolPagesColour.total.toFixed(2)}
                                                    </TableCell>
                                                </TableRow>
                                            )}

                                            {/*=========================spacing========================= */}
                                            <TableRow>
                                                <TableCell className={classes.innerBorders} />
                                                <TableCell colSpan={2} className={classes.innerBorders} />
                                                <TableCell className={classes.innerBorders} />
                                                <TableCell className={classes.innerBorders} />
                                            </TableRow>
                                        </React.Fragment>
                                    ))}
                                {clientsData.pouch && clientsData.pouch.quantity > 0 && (
                                    <>
                                        <TableRow>
                                            <TableCell className={classes.innerBorders} align="center">
                                                {clientsData.pouch.quantity}
                                            </TableCell>
                                            <TableCell className={classes.innerBorders} colSpan={2}>
                                                {clientsData.pouch.description}
                                            </TableCell>
                                            <TableCell className={classes.innerBorders} align="center">
                                                ${clientsData.pouch.unitPrice.toFixed(2)}
                                            </TableCell>
                                            <TableCell className={classes.innerBorders} align="right">
                                                ${clientsData.pouch.total.toFixed(2)}
                                            </TableCell>
                                        </TableRow>
                                        {/* <TableRow>
                                            <TableCell className={classes.innerBorders} />
                                            <TableCell colSpan={2} className={classes.innerBorders} />
                                            <TableCell className={classes.innerBorders} />
                                            <TableCell className={classes.innerBorders} />
                                        </TableRow> */}
                                    </>
                                )}
                                {clientsData.teacherPlanner && (
                                    <>
                                        <TableRow>
                                            <TableCell className={classes.innerBorders} align="center">
                                                {clientsData.teacherPlanner.quantity}
                                            </TableCell>
                                            <TableCell className={classes.innerBorders} colSpan={2}>
                                                {clientsData.teacherPlanner.description}
                                            </TableCell>
                                            <TableCell className={classes.innerBorders} align="center">
                                                ${clientsData.teacherPlanner.unitPrice.toFixed(2)}
                                            </TableCell>
                                            <TableCell className={classes.innerBorders} align="right">
                                                ${clientsData.teacherPlanner.total.toFixed(2)}
                                            </TableCell>
                                        </TableRow>
                                        {/* <TableRow>
                                            <TableCell className={classes.innerBorders} />
                                            <TableCell colSpan={2} className={classes.innerBorders} />
                                            <TableCell className={classes.innerBorders} />
                                            <TableCell className={classes.innerBorders} />
                                        </TableRow> */}
                                    </>
                                )}
                                {clientsData.staffMonthly && (
                                    <>
                                        <TableRow>
                                            <TableCell className={classes.innerBorders} align="center">
                                                {clientsData.staffMonthly.quantity}
                                            </TableCell>
                                            <TableCell className={classes.innerBorders} colSpan={2}>
                                                {clientsData.staffMonthly.description}
                                            </TableCell>
                                            <TableCell className={classes.innerBorders} align="center">
                                                ${clientsData.staffMonthly.unitPrice.toFixed(2)}
                                            </TableCell>
                                            <TableCell className={classes.innerBorders} align="right">
                                                ${clientsData.staffMonthly.total.toFixed(2)}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.innerBorders} />
                                            <TableCell colSpan={2} className={classes.innerBorders} />
                                            <TableCell className={classes.innerBorders} />
                                            <TableCell className={classes.innerBorders} />
                                        </TableRow>
                                    </>
                                )}
                                {clientsData.planners.length !== 0 && clientsData.customFrontCover && (
                                    <TableRow>
                                        <TableCell className={classes.innerBorders} align="center">
                                            1
                                        </TableCell>
                                        <TableCell className={classes.innerBorders} colSpan={2}>
                                            Front Custom Cover
                                        </TableCell>
                                        <TableCell className={classes.innerBorders} align="center">
                                            ${clientsData.customFrontCoverPrice.toFixed(2)}
                                        </TableCell>
                                        <TableCell className={classes.innerBorders} align="right">
                                            ${clientsData.customFrontCoverPrice.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                )}
                                {clientsData.planners.length !== 0 && clientsData.customBackCover && (
                                    <TableRow>
                                        <TableCell className={classes.innerBorders} align="center">
                                            1
                                        </TableCell>
                                        <TableCell className={classes.innerBorders} colSpan={2}>
                                            Back Custom Cover
                                        </TableCell>
                                        <TableCell className={classes.innerBorders} align="center">
                                            ${clientsData.customBackCoverPrice.toFixed(2)}
                                        </TableCell>
                                        <TableCell className={classes.innerBorders} align="right">
                                            ${clientsData.customBackCoverPrice.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                )}
                                {clientsData.baseFee > 0 && (
                                    <TableRow>
                                        <TableCell className={classes.innerBorders} align="center">
                                            1
                                        </TableCell>
                                        <TableCell className={classes.innerBorders} colSpan={2}>
                                            Less than 100 Units Fee
                                        </TableCell>
                                        <TableCell className={classes.innerBorders} align="center">
                                            ${clientsData.baseFee.toFixed(2)}
                                        </TableCell>
                                        <TableCell className={classes.innerBorders} align="right">
                                            ${clientsData.baseFee.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                )}
                                {clientsData.baseFeeDiscount > 0 && (
                                    <TableRow>
                                        <TableCell className={classes.innerBorders} align="center">
                                            1
                                        </TableCell>
                                        <TableCell className={classes.innerBorders} colSpan={2}>
                                            Discount
                                        </TableCell>
                                        <TableCell className={classes.innerBorders} align="center">
                                            -${clientsData.baseFeeDiscount.toFixed(2)}
                                        </TableCell>
                                        <TableCell className={classes.innerBorders} align="right">
                                            -${clientsData.baseFeeDiscount.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <Table className={clsx(classes.innerBorders, classes.coverContainer)} size="small">
                            {clientsData.planners.length !== 0 && (
                                <GridList className={classes.covers}>
                                    {clientsData.planners.map((p, i) => (
                                        <span key={i} className={classes.cover}>
                                            <div>
                                                <Typography noWrap>
                                                    {p.description} - {p.frontCover.description}
                                                </Typography>
                                            </div>
                                            <div>
                                                <Tooltip title={p.frontCover.description}>
                                                    <img
                                                        src={p.frontCover.coverThumb}
                                                        alt={`${p.description} Front cover`}
                                                    />
                                                </Tooltip>
                                            </div>
                                        </span>
                                    ))}
                                </GridList>
                            )}
                            {clientsData.pouch.quantity > 0 && (
                                <GridList className={classes.covers}>
                                    <span className={classes.cover}>
                                        <div>
                                            <Typography noWrap>{clientsData.pouch.description}</Typography>
                                        </div>
                                        <div>
                                            <Tooltip title={clientsData.pouch.description}>
                                                <img
                                                    style={{
                                                        maxWidth: "170px",
                                                        margin: 0,
                                                        padding: 0,
                                                        backgroundPosition: "center",
                                                    }}
                                                    src={clientsData.pouch.coverThumb}
                                                    alt={clientsData.pouch.description}
                                                />
                                            </Tooltip>
                                        </div>
                                    </span>
                                </GridList>
                            )}
                        </Table>

                        <Table className={classes.borders} size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        rowSpan={6}
                                        colSpan={3}
                                        className={clsx(classes.topBorder, classes.innerBorders)}
                                    >
                                        <Typography variant="h4" className={classes.notInvoice} align="center">
                                            THIS IS NOT AN INVOICE
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.topBorder}>Subtotal</TableCell>
                                    <TableCell align="right" className={classes.innerBorders}>
                                        $ {clientsData.calcSubtotal.toFixed(2)}
                                    </TableCell>
                                    {/* <TableCell className={classes.topBorder}>Base Fee</TableCell>
                                    <TableCell align="right" className={clsx(classes.topBorder, classes.innerBorders)}>
                                        $ {clientsData.baseFee.toFixed(2)}
                                    </TableCell> */}
                                </TableRow>
                                {/* <TableRow>
                                    <TableCell>Discount</TableCell>
                                    <TableCell
                                        align="right"
                                        className={
                                            // clsx(classes.topBorder,
                                            classes.innerBorders
                                            // )
                                        }
                                    >
                                        $ {clientsData.baseFeeDiscount.toFixed(2)}
                                    </TableCell>
                                </TableRow> */}
                                {/* <TableRow>
                                    <TableCell
                                    // className={classes.topBorder}
                                    >
                                        Subtotal
                                    </TableCell>
                                    <TableCell align="right" className={classes.innerBorders}>
                                        $ {clientsData.calcSubtotal.toFixed(2)}
                                    </TableCell>
                                </TableRow> */}
                                <TableRow>
                                    <TableCell>Shipping{clientsData.shippingType === 2 && ": TBD"}</TableCell>
                                    <TableCell className={classes.innerBorders} align="right">
                                        {clientsData.shippingType === 2
                                            ? "TBD"
                                            : `$ ${clientsData.calcShipping.toFixed(2)}`}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>GST</TableCell>
                                    <TableCell className={classes.innerBorders} align="right">
                                        $ {clientsData.calcGST.toFixed(2)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>PST</TableCell>
                                    <TableCell className={classes.innerBorders} align="right">
                                        $ {clientsData.calcPST.toFixed(2)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>HST</TableCell>
                                    <TableCell className={classes.innerBorders} align="right">
                                        $ {clientsData.calcHST.toFixed(2)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Total Tax</TableCell>
                                    <TableCell className={classes.innerBorders} align="right">
                                        $ {(clientsData.calcGST + clientsData.calcPST + clientsData.calcHST).toFixed(2)}
                                    </TableCell>
                                </TableRow>
                                <TableRow className={classes.borders}>
                                    <TableCell colSpan={3} />
                                    <TableCell>Order Total</TableCell>
                                    <TableCell align="right">$ {clientsData.calcTotal.toFixed(2)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                        <List dense={true}>
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Typography>
                                            Handbook Deadline - {dateFormat(clientsData.materialHandbookDate)}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Typography>
                                            Quantity/Cover Deadline - {dateFormat(clientsData.materialCoverDate)}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Typography>
                                            Delivery Week -{" "}
                                            {dateFormat(
                                                clientsData.deliveryWeekStartDate,
                                                clientsData.deliveryWeekEndDate
                                            )}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    align="center"
                                    primary={
                                        <Typography variant="subtitle1">
                                            <em>*An order details email will be sent in the Spring*</em>
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        </List>
                        <div className={classes.notes}>
                            <TextField
                                id="notes"
                                label="Changes Requested:"
                                fullWidth
                                multiline
                                rows={4}
                                InputProps={{
                                    classes: {
                                        input: notes.length !== 0 && classes.highlight,
                                    },
                                }}
                                placeholder="Please describe your changes, if any"
                                variant="outlined"
                                value={notes}
                                onChange={handleNotes}
                            >
                                <Typography className={classes.highlight}></Typography>
                            </TextField>
                        </div>
                        <Grid container margin="none" padding="none" className={classes.signature}>
                            <Grid item md={6} sm={6} xs={12}>
                                <FormControl fullWidth className={classes.textField}>
                                    <TextField
                                        required
                                        label="Authorization Signature: "
                                        onChange={handleSignature}
                                        value={signature}
                                        placeholder="Type name here"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6} sm={6} xs={12}>
                                <FormControl fullWidth className={classes.textField}>
                                    <TextField disabled label="Date: " value={date} />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Typography variant="subtitle2" className={classes.signatureLabel}>
                            Signature:
                        </Typography>
                        <Box className={classes.signatureBox}>
                            <div className="signature">{signature}</div>
                        </Box>
                        <div className={classes.btn}>
                            <Button variant="outlined" color="primary" className={classes.btn} onClick={handleSubmit}>
                                Submit
                            </Button>
                        </div>
                    </Paper>
                </>
            )}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="Please review your order"
                aria-describedby="Please review your order"
            >
                <DialogTitle>{"Review your order"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please review, sign and submit your order. You can also add a note to request a change to your
                        order.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={error !== ""}
                onClose={() => setError("")}
                aria-labelledby="Required* fields unfilled"
                aria-describedby="Required* fields unfilled"
            >
                <DialogTitle>{"Required* fields unfilled"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{error}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setError("")} color="primary">
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={invalidLink} aria-labelledby="Inactive Link" aria-describedby="Inactive Link">
                <DialogTitle>{"Inactive Link"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This link has expired or has been responded to. Please login to{" "}
                        <a href="/">my.firstclassplanners.ca</a> to access your Order, or contact your Sales
                        Representative. Please close this page.
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Dialog open={validSubmittion} aria-labelledby="Request Submitted" aria-describedby="Request Submitted">
                <DialogTitle>{"Thank you! Your request has been submitted."}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        If you fail to receive a copy of signed order form, please check your spam folder.
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Contract;
