import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import Alerts from "../components/Portal/Alerts/Alerts";
import Dashboard from "../components/Portal/Dashboard/Dashboard";
import Orders from "../components/Portal/Orders/Orders";
import withAuth from "../withAuth";

class PortalRouter extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route path="/crm/" exact component={Dashboard} />
          <Route path="/crm/alerts" exact component={withAuth(Alerts)} />
          <Route path="/crm/orders" exact component={withAuth(Orders)} />
        </Switch>
      </div>
    );
  }
}

export default PortalRouter;
