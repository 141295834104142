import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Copyright from "../Helpers/CopyRight";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: "url(/images/background2.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  card: {
    width: "60%",
    margin: theme.spacing(12, "auto"),
  },
  img: {
    display: "block",
    margin: theme.spacing(3, "auto", 1, "auto"),
    width: "55%",
  },
  content: {
    padding: theme.spacing(3, 2, 5),
  },
  button: {
    margin: theme.spacing(0, "auto", 3),
  },
}));

const UserValidation = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.background}
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <div>
        <Card className={classes.card}>
          <div className={classes.img}>
            <img
              alt="Company Logo"
              src="/images/FirstClassLogoColour.jpg"
              className={classes.img}
            />
          </div>
          <CardContent className={classes.content}>
            <Typography gutterBottom variant="h3" component="h2" align="center">
              Thank you for joining us!
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              component="p"
              align="center"
            >
              Please check your email and verify your email before signing in.
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              component={Link}
              to={`/login`}
            >
              Return to Login
            </Button>
          </CardActions>
        </Card>
        <Copyright />
      </div>
    </Grid>
  );
};

export default UserValidation;
