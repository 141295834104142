import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import axios from 'axios';
import { List } from '@material-ui/core';
// Divider,
import MenuItem from './MenuItem';

const Menu = (props) => {
    const [menuList, setMenuList] = useState([]);
    // const [section, setSection]  = useState(0);
    // const [divider, setDivider] = useState(false);

    // fetch('http://localhost:8080/mock/menuList')
    // .then(response => response)
    // .then(data => setMenuList(data));

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;
        
        // retrieving data from the API
        axios.get("/mock/menuList", { signal: signal })
        // axios.get("/mock/menuList")
            .then(response => response)
            .then(data => setMenuList(data.data)
        );
        return () => {
            abortController.abort();
        }
    }, []);

    // useEffect(() => {
    //     setDivider(!divider)
    // }, [section]); 

    // console.log(menuList);
    return (
        <div>
            <List>
                {menuList.map((item) => {
                    // setSection(item.section)
                    return (
                        <div key={item.label}>
                            <MenuItem 
                                openDrawer={props.open}
                                label={item.label} 
                                depth={item.depth} 
                                path={item.path} 
                                icon={item.icon} 
                                subMenu={item.subMenu ? item.subMenu : []} 
                            />
                            {/* {(item.section !== section) && <Divider />} */}
                            {/* {divider && <Divider />} */}
                        </div>
                    )
                })}
            </List>

        </div>
    );
}

// export default Menu;
const mapStateToProps = (state) => {
    return {
      open: state.drawerOpen,
    }
  };
  
export default connect(mapStateToProps)(Menu);