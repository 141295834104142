import React, { useState } from "react";
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    List,
    ListItem,
    makeStyles,
    TextField,
    Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import api_endpoints from "../../constants/api_endpoints";

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundImage: "url(/images/background.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundColor: theme.palette.grey[50],
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    card: {
        width: "45%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
    },
    logo: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: theme.spacing(1),
        width: "40%",
    },
    content: {
        padding: theme.spacing(1, 2, 2, 3),
    },
    noSpinner: {
        "&[type=number]": {
            "-moz-appearance": "textfield",
        },
        "&::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "&::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
    },
    noPadding: {
        padding: theme.spacing(0, 2),
        margin: 0,
    },
    policy: {
        display: "flex",
        alignItems: "center",
    },
    policyCheckbox: {
        marginLeft: "-14px",
    },
    submitButton: {
        marginTop: theme.spacing(2),
        width: "100%",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const Signup = (props) => {
    const classes = useStyles();
    const [updateMessage, setUpdateMessage] = useState("");
    const [formInfo, setFormInfo] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        verifyPassword: "",
        termsAccepted: false,
    });
    const handleInputChange = (event) => {
        const { value, name } = event.target;
        if (name === "termsAccepted") {
            setFormInfo({
                ...formInfo,
                termsAccepted: !formInfo.termsAccepted,
            });
        } else {
            setFormInfo((prev) => ({ ...prev, [name]: value }));
        }
    };

    // const [open, setOpen] = useState(true);

    const handleClose = () => {
        // setOpen(!open);
        if (
            updateMessage === "Please Note: we are updating the site and you may not be able to log in at the moment."
        ) {
            props.history.push("/verifyemail");
        } else {
            setUpdateMessage("");
        }
    };

    useEffect(() => {
        setUpdateMessage(
            " Your request has been submitted. Please take a moment to create an account where you will be able to view your order, submit school pages, track delivery status and find a copy of your invoice."
        );
        const url = window.location.href;
        const contractId = url.split("?")[1];

        fetch(api_endpoints.api().signup + contractId)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    const error = new Error(res.error);
                    throw error;
                }
            })
            .then((data) => {
                setFormInfo({
                    ...formInfo,
                    ...data,
                });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (formInfo.password !== formInfo.verifyPassword) {
            window.alert("Passwords do not match.");
        } else {
            fetch(api_endpoints.api().signup + formInfo.key, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify(formInfo),
            })
                .then((res) => {
                    if (res.ok) {
                        setUpdateMessage(
                            "Please Note: we are updating the site and you may not be able to log in at the moment."
                        );
                        // setOpen(true);
                        // props.history.push("/verifyemail");
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    let errorMess = "";
                    if (data) {
                        Object.values(data).map((err) => err.map((m) => (errorMess += m + "\n")));
                        window.alert("errorMess");
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };

    return (
        <div>
            <Grid
                container
                className={classes.background}
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: "100vh" }}
            >
                <Card className={classes.card}>
                    <form onSubmit={handleSubmit}>
                        <CardContent className={classes.content}>
                            <List>
                                <ListItem>
                                    <img
                                        alt="Brand Logo"
                                        src="/images/FirstClassLogoColour.jpg"
                                        className={classes.logo}
                                    />
                                </ListItem>
                                <ListItem className={classes.noPadding}>
                                    <div>
                                        <Typography variant="h3">Create Account</Typography>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Please fill in your information
                                        </Typography>
                                    </div>
                                </ListItem>
                                {/* <ListItem> */}
                                <Grid container>
                                    <Grid item sm={12} md={6}>
                                        <ListItem>
                                            <TextField
                                                required
                                                fullWidth
                                                label="First Name"
                                                name="firstName"
                                                variant="outlined"
                                                value={formInfo.firstName}
                                                onChange={handleInputChange}
                                            />
                                        </ListItem>
                                    </Grid>
                                    <Grid item sm={12} md={6}>
                                        <ListItem>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Last Name"
                                                name="lastName"
                                                variant="outlined"
                                                value={formInfo.lastName}
                                                onChange={handleInputChange}
                                            />
                                        </ListItem>
                                    </Grid>
                                </Grid>
                                {/* </ListItem> */}
                                <Grid container>
                                    <Grid item sm={12} md={6}>
                                        <ListItem>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Email address"
                                                name="email"
                                                type="email"
                                                variant="outlined"
                                                value={formInfo.email}
                                                onChange={handleInputChange}
                                            />
                                        </ListItem>
                                    </Grid>
                                    <Grid item sm={12} md={6}>
                                        <ListItem>
                                            <TextField
                                                required
                                                fullWidth
                                                type="number"
                                                className={classes.noSpinner}
                                                label="Phone number"
                                                name="phone"
                                                variant="outlined"
                                                value={formInfo.phone}
                                                onChange={handleInputChange}
                                            />
                                        </ListItem>
                                    </Grid>
                                </Grid>

                                <ListItem>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Password"
                                        name="password"
                                        type="password"
                                        variant="outlined"
                                        onChange={handleInputChange}
                                    />
                                </ListItem>
                                <ListItem>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Verify Password"
                                        name="verifyPassword"
                                        type="password"
                                        variant="outlined"
                                        onChange={handleInputChange}
                                    />
                                </ListItem>
                                <ListItem>
                                    <div className={classes.policy}>
                                        <Checkbox
                                            required
                                            className={classes.policyCheckbox}
                                            color="primary"
                                            name="termsAccepted"
                                            value={formInfo.termsAccepted}
                                            onChange={handleInputChange}
                                        />
                                        <Typography color="textSecondary" variant="body1">
                                            I have read the{" "}
                                            <Link color="secondary" to="#" underline="always" variant="h6">
                                                Terms and Conditions
                                            </Link>
                                        </Typography>
                                    </div>
                                </ListItem>
                                <ListItem>
                                    <Button
                                        className={classes.submitButton}
                                        color="primary"
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        // component={Link}
                                        // to="/verifyemail"
                                    >
                                        Submit
                                    </Button>
                                </ListItem>
                            </List>
                        </CardContent>
                    </form>
                    <CardMedia className={classes.media} image="/images/auth.png" title="Cover" />
                </Card>
            </Grid>

            <Dialog
                // open={open}
                open={updateMessage !== ""}
                // onClose={handleClose}
                aria-labelledby="Order Submitted"
                aria-describedby="Order Submitted"
            >
                <DialogTitle>
                    {updateMessage ===
                    "Please Note: we are updating the site and you may not be able to log in at the moment."
                        ? "Important Message"
                        : "Thank you! Your request has been submitted."}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        style={{
                            color:
                                updateMessage ===
                                    "Please Note: we are updating the site and you may not be able to log in at the moment." &&
                                "red",
                        }}
                    >
                        {updateMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Signup;
