import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../components/Public/Home";
import Login from "../components/Public/Login";
import Portal from "../components/Portal/Portal";
import Error404 from "../components/Helpers/404";

import withAuth from "../withAuth";
import Contract from "../components/Public/Contract";
import Signup from "../components/Public/Signup";
import UserValidation from "../components/Public/VerifyEmail";
import EmailValidation from "../components/Public/EmailConfirmation";

export default function AppRouter() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/login" exact component={Login} />
      <Route path="/crm" component={withAuth(Portal)} />

      <Route path="/contract" component={Contract} />
      <Route path="/signup" component={Signup} />
      <Route path="/verifyemail" component={UserValidation} />
      <Route path="/emailconfirmation" component={EmailValidation} />

      <Route path="*" component={Error404} />
    </Switch>
  );
}
