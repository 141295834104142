export default {
  settings: {
    env: "PROD", // PROD or DEV
    api_host_dev: "https://pes-api-client.saphera.com",
    api_host_prod: "https://pes-api-client.saphera.com",
    api_path: "/api/v1/",
    api_request_header_pagination: "x-pagination",
  },
  api() {
    // Set environment variables
    var host = "";
    if (this.settings.env === "DEV") {
      host = this.settings.api_host_dev;
    } else {
      host = this.settings.api_host_prod;
    }

    const route = host + this.settings.api_path;

    return {
      client_contract: route + "contracts/",
      signing: route + "signing/",
      signup: route + "signup/",
    };
  },
};
