import React, { useState } from 'react';

import { IconButton, Badge, Divider } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popover from '@material-ui/core/Popover';

import AccountCircle from '@material-ui/icons/AccountCircle';
import { useHistory } from 'react-router-dom';

function UserMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const history = useHistory();

    const logout = event => {
        event.preventDefault();
        fetch('/api/logout', {
            method: 'GET',
        })
        .then(res => {
            console.log(res.status);
            if (res.status === 200){
                history.push('/login');
            }
            else {
                const error = new Error(res.error);
                throw error;
            }
        })
        .catch (err => {
            console.error(err);
            alert('Error logging out');
        })
        handleClose(event);
    }

    return (
        <div>
            <IconButton 
                color="inherit" 
                onClick={handleClick}
            >
                <Badge color="secondary">
                    <AccountCircle />
                </Badge>
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}
            >
                <MenuList>
                    <MenuItem>Profile</MenuItem>
                    <Divider />
                    <MenuItem onClick={logout}>Logout</MenuItem>
                </MenuList>
            </Popover>
        </div>
    );
}

export default UserMenu;