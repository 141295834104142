import React, { useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

function createData(orderId, orderDate, numberofItems, status) {
  return { orderId, orderDate, numberofItems, status };
}

const rows = [
  createData("12194786", "14-04-2020", "10", "Shipped"),
  createData("20986224", "14-04-2020", "14", "Packed"),
  createData("36795678", "17-04-2020", "16", "Packed"),
  createData("41244679", "18-04-2020", "3", "Incomplete"),
  createData("57457434", "21-04-2020", "7", "Incomplete"),
  createData("64748856", "24-04-2020", "20", "Incomplete"),
  createData("70897956", "27-04-2020", "21", "Incomplete"),
];

export default function Orders() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <h1>Sample Orders Section</h1>
      <p>
        This is a sample page. In this section, a list of the orders that needs
        to be fufilled can be display. Past orders may also be accessible in
        this section.
      </p>
      <Paper>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Order ID</TableCell>
                <TableCell>Order Date</TableCell>
                <TableCell align="center">Number of Items</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((order) => (
                  <TableRow hover key={order.orderId}>
                    <TableCell>{order.orderId}</TableCell>
                    <TableCell>{order.orderDate}</TableCell>
                    <TableCell align="center">{order.numberofItems}</TableCell>
                    <TableCell>{order.status}</TableCell>
                    <TableCell>
                      <VisibilityIcon />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 15, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
