import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button, makeStyles, Grid } from "@material-ui/core";
import LockOpenIcon from "@material-ui/icons/LockOpen";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: "url(/images/background.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  containerBlock: {
    alignItems: "center",
    color: "#4d4d4d",
    maxWidth: "850px",
    margin: "0 auto",
    left: "0",
    right: "0",
    padding: "20px",
    position: "absolute",
    textAlign: "center",
    transform: "translateY(-50%)",
    top: "50%",
  },
  titleText: {
    color: "white",
    fontWeight: "bold",
    marginBottom: "25px",
  },
  subtitleText: {
    color: "white",
  },
  button: {
    margin: theme.spacing(3, 1),
  },
}));

export default function Home() {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.background}
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <div className={classes.containerBlock}>
        <Typography component="h1" variant="h1" className={classes.titleText}>
          First Class Planners
        </Typography>
        <Typography variant="h2" className={classes.subtitleText}>
          A First Class Experience in Every Way
        </Typography>
        <Button
          component={Link}
          to="/login"
          variant="contained"
          color="primary"
          size="large"
          className={classes.button}
          startIcon={<LockOpenIcon />}
        >
          Sign In
        </Button>
      </div>
    </Grid>
  );
}
