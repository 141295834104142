import React from "react";
import { connect } from "react-redux";

import { makeStyles, Box } from "@material-ui/core";

import CopyRight from "../Helpers/CopyRight";
import Header from "../../layout/Header";
import PortalRouter from "../../routes/PortalRouter";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  appBarSpacer: {
    paddingTop: theme.spacing(4),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  center: {
    paddingTop: theme.spacing(6),
    flexGrow: 1,
    maxWidth: "100%",
    overflowX: "hidden",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

function Portal(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header />
      <main className={classes.content}>
        <div className={classes.appBarSpacer}>
          <div className={classes.center}>
            <PortalRouter />
            <Box pt={4}>
              <CopyRight />
            </Box>
          </div>
        </div>
      </main>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    open: state.drawerOpen,
  };
};

export default connect(mapStateToProps)(Portal);
