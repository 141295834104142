const Settings = {};
Settings.list = {
  signonBackgrounds: [
    {
      image: "/images/background1.jpg",
      alt: "",
    },
    {
      image: "/images/background2.jpg",
      alt: "",
    },
  ],
};

export default Settings;
